import { isLocal, environment } from "App/Helpers/enviroments";

const env = environment();
let config;

const dev = {
    rid: 96,
    buildVersion: '1.0.27.1',
    campaignId: 25,
    source: "Snake Unity Web Dev",
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    oneSignalAppId: "4aee9612-829c-470f-b497-4b935fac6ecd", // OneSignal dev app name: Games_pwa US Development
    domain: 'https://game-dev.snakecolorbrake.com/',
    modalsUrl: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=',
    gaTrackId: 'G-TMLCCY2XDK', // app name: Snake-Dev-PWA-Web; stream name: Snake Unity Dev
    sentryEnabled: true,
    adProvider: 'adinplay',
    lang: "en",
};

const prod = {
    rid: 118,
    buildVersion: '1.0.27.1',
    campaignId: 28,
    source: "Snake Unity Web Prod",
    sharpstarApiUrl: 'https://dc.sharpstar.pro/',
    oneSignalAppId: "a61cec45-edfe-4f0d-b896-78a3b003779a", // OneSignal prod app name: Games_web US Production
    domain: 'https://snakecolorbrake.com/',
    modalsUrl: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=',
    gaTrackId: 'G-TW3JV6VRDL', // app name: Snake Color Break GA4; stream name: snakecolorbrake.com
    sentryEnabled: true,
    adProvider: 'adinplay',
    lang: "en",
};

if (env === 'production') {
    config = prod;
} else if (env === 'development'){
    config = dev;
} else {
    dev.rid = 66;
    dev.campaignId = 18;
    dev.oneSignalAppId = "f5eff9bb-4eca-4d93-bedc-ccaafba3dff5"; // OneSignal app name: Sport US Development
    dev.domain = "https://127.0.0.1:8080/";

    config = dev;
}

// add userId value if needed
export const gameAnalyticsConfig = {
    gameKey: "11e2d22cd4ccd6441751c221ade147f1",
    gameSecret: "b60ab3b934993cad95037de83d3f317989212b26",
}

export const oneSignalConfig = {
    appId: config.oneSignalAppId,
    safari_web_id: env === 'production' ? 'web.onesignal.auto.5d56d362-2565-48e1-9c7d-b5c325eeeb04' : "web.onesignal.auto.52bd6d36-ef00-42e1-a687-b4f3eaae4ff3",
    serviceWorkerPath: isLocal() ? 'OneSignalSDKWorker.js' : 'push/OneSignalSDKWorker.js',
    serviceWorkerParam: {
        scope: isLocal() ? '/' : '/push/'
    },
    notifyButton: {
        enable: false,
    },
    welcomeNotification: {
        disable: true,
    },
    allowLocalhostAsSecureOrigin: isLocal(),
    autoRegister: false,
}

export const gameContainerBg = {
    "D9D3CE": "#F7F1EC",
    "FFBEBD": "#FFEEED",
    "AED9FF": "#C2EDFF",
    "FFFDA8": "#FFFEE5",
    "161414": "#2A2828",
    "FFFFFF": "rgba(117, 8, 255, .05)"
}

export default config;
