// download the latest version of game analytics SDK
// place it in the project root folder and
//  add link in index.html like <script src="/path/to/GameAnalytics.js" />
import * as Sentry from "@sentry/browser";

/**
 * Game analytics service provider
 *
 * @param gameKey: string(required)
 * @param gameSecret: string(required)
 * @param environment: "production" || "development" || "local"(required)
 * @param build: string(required)
 * @param userId: string(optional)
 */
export default class GameAnalytics {
    constructor(gameKey, gameSecret, environment, build, userId) {
        this.userId = userId || 'default';

        this.init(gameKey, gameSecret, environment, build, userId);
    }

    /**
     * Init game analytics
     *
     * @param gameKey: string(required)
     * @param gameSecret: string(required)
     * @param environment: "production" || "development" || "local"(required)
     * @param build: string(required)
     * @param userId: string(optional)
     */
    init(gameKey, gameSecret, environment, build, userId) {
        try {
            environment !== 'production' && console.info(
                '%cstart Init Game Analytics',
                'background: #ACC8E5; color: #112A46; font-weight: 900'
            );
            gameanalytics.GameAnalytics.setEnabledVerboseLog(environment !== "production");
            userId && gameanalytics.GameAnalytics.configureUserId(userId);

            gameanalytics.GameAnalytics.configureBuild(build);
            gameanalytics.GameAnalytics.initialize(gameKey, gameSecret);
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    /**
     * Send design event to game analytics
     *
     * @param eventName: string(required)
     * @param value: string(optional)
     * @param fieldsJson: { key[string]: string || number }(optional)
     */
    sendDesignEvent(eventName, value, fieldsJson) {
        gameanalytics.GameAnalytics.addDesignEvent(
            eventName,
            value && value,
            fieldsJson && JSON.parse(fieldsJson)
        );
    }

    /**
     * Send progression event to game analytics
     *
     * @param progressionStatus: "Start" | "Fail" | "Complete" (required)
     * @param progression1: string (optional)
     * @param progression2: string (optional)
     * @param progression3: string (optional)
     * @param score: number (optional)
     */
    sendProgressionEvent(progressionStatus, progression1, progression2, progression3, score) {
        gameanalytics.GameAnalytics.addProgressionEvent(
            gameanalytics.EGAProgressionStatus[progressionStatus],
            progression1,
            progression2,
            progression3,
            score
        );
    }

    /**
     * Send progression event to game analytics
     *
     * @param eventType: 'Show' | 'FailedShow' (required) (can be extended according to GA documentation)
     * @param adType: 'RewardedVideo' | 'Banner' (required) (can be extended according to GA documentation)
     * @param adSdkName: string (required) - name of the ad provider (adinplay), lowercase with no spaces or underscores
     * @param adPlacement: (required) placement/identifier of the ad within the game (end_of_game), max 64 characters
     */
    sendAdEvent(eventType, adType, adSdkName, adPlacement) {
        gameanalytics.GameAnalytics.addAdEvent(
            gameanalytics.EGAAdAction[eventType],
            gameanalytics.EGAAdType[adType],
            adSdkName,
            adPlacement
        );
    }

    /**
     * Send error event to game analytics
     *
     * @param message: string(required)
     * @param errorType: 'Error' | 'Warning' | 'Info' | 'Debug' | 'Critical' (required)
     */
    sendErrorEvent(errorType, message) {
        gameanalytics.GameAnalytics.addErrorEvent(
            gameanalytics.EGAErrorSeverity[errorType],
            message
        );
    }
}
