import React from 'react';
import * as Sentry from "@sentry/browser";
import UrlParams from "App/Helpers/UrlParams";
import SharpstarServiceHandler from "App/Services/Handlers/SharpstarServiceHandler";
import config, { gameAnalyticsConfig } from './config';
import CookieService from "App/Services/CookieService";
import WebsiteDataService from "App/Services/WebsiteDataService";
import SentryConfig from "App/Helpers/SentryConfig";
import { environment, isProd } from "App/Helpers/enviroments";
import GoogleAnalytics from "App/Services/Analytics/GA4";
import GameAnalytics from "App/Services/Analytics/GameAnalytics";
import checkAdBlockers from "App/Helpers/checkAdBlockers";
import Landing from "./components/Landing";
import GameContainer from "./components/Game/components/GameContainer";
import AdBlockerModal from "./components/AdBlockerModal";
import Modal from "App/React/_UI/Modal/Modal";
import "./main.scss";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phase: null,
            offerId: null,
            adBlocker: false,
            showAdBlockerModal: false,
            termsModalOpen: false,
            pushPrompt: null,
            analytics: null,
            unityConfig: {
                buildVersion: null,
                buildUrl: null,
            },
            termsModalText: {
                title: '',
                content: ''
            }
        };

        this.setUrlParam = this.setUrlParam.bind(this);
        this.onPlayBtnClickHandler = this.onPlayBtnClickHandler.bind(this);
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
        this.resolveAdsBlocked = this.resolveAdsBlocked.bind(this);
        this.showTermsUse = this.showTermsUse.bind(this);
        this.hideTermsUse = this.hideTermsUse.bind(this);

        this.setUrlParamValues(); // Set refids, hash, rid
        this.initServices(); //Init SharpStar, Sentry, Google analytics, websiteDataService
    }

    setUrlParam(name) {
        if (this[name] !== '0' && this[name] !== null) { // If exists save it to local storage.
            localStorage.setItem(name, this[name]);
        } else if (localStorage.getItem(name)) {  // Else get from local storage and set
            this[name] = localStorage.getItem(name);
        }
    }

    setUrlParamValues(){
        this.refid3 = UrlParams.refid3;
        this.refid2 = UrlParams.refid2;
        this.refid1 = UrlParams.refid1;
        this.hash = UrlParams.hash;
        this.rid = UrlParams.rid;

        this.setUrlParam('refid3');
        this.setUrlParam('refid2');
        this.setUrlParam('refid1');
        this.setUrlParam('hash');
        this.setUrlParam('rid');
    }

    initServices() {
        this.sharpstarDataService = new SharpstarServiceHandler(
            config.sharpstarApiUrl,
            config.source,
            config.campaignId,
            new CookieService(config.domain)
        );

        this.websiteDataService = new WebsiteDataService(config.modalsUrl, config.lang);

        SentryConfig.init(
            config.sentryEnabled,
            null,
            config.source,
            config.campaignId,
            null,
            document.location.host,
            environment(),
            this.refid3
        );
    }

    checkPhase() {
        const phase = localStorage.getItem('phase');

        !phase && localStorage.setItem('phase', 'landing');

        this.setState({
            phase: !phase || phase === 'landing' ? 'landing' : 'game',
        });
    }

    onPlayBtnClickHandler() {
        if (this.state.adBlocker) {
            this.setState({ showAdBlockerModal: true });
        } else {
            this.setState({ phase: 'game' });
            localStorage.setItem('phase', 'game');
            this.state.analytics && this.state.analytics.sendDesignEvent('Site Play Button');
        }
    }

    resolveAdsBlocked(adsBlocked) {
        if (adsBlocked) {
            this.state.phase === 'game' && this.setState({ showAdBlockerModal: true });
            this.setState({ adBlocker: adsBlocked });
        } else {
            new GoogleAnalytics(
                true,
                config.source,
                config.gaTrackId,
                {},
                { 'debug_mode': environment() !== 'production' }
            );

            this.setState({
                analytics: new GameAnalytics(
                    gameAnalyticsConfig.gameKey,
                    gameAnalyticsConfig.gameSecret,
                    environment(),
                    this.state.unityConfig.buildVersion
                )
            })
        }
    }

    showTermsUse(type) {
        this.setState({ termsModalOpen: true });

        this.websiteDataService
            .getText(type)
            .then((res) => {
                this.setState({
                    termsModalText: res
                });
            })
            .catch((e) => Sentry.captureException(e));
    }

    hideTermsUse() {
        this.setState({
            termsModalOpen: false,
            termsModalText: {
                title: '',
                content: ''
            }
        });
    }

    handleBeforeUnload = () => {
        const { phase, offerId, adBlocker } = this.state;

        Sentry.withScope(function (scope) {
            scope.setTag("adBlocked", `${adBlocker ? 'true': 'false'}`);

            Sentry.captureException(new Error(`page unload: phase ${phase}; offerId: ${offerId};`))
        })
    }

    async componentDidMount() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        this.checkPhase();
        this.sharpstarDataService
            .getOffers(undefined, 0, this.rid)
            .then((response) => {
                const { id, content } = response.offers[0];

                localStorage.setItem('offerId', id);
                localStorage.setItem('buildVersion', content["game-version"]);

                this.setState({
                    offerId: id,
                    unityConfig: {
                        buildVersion: content["game-version"],
                        buildUrl: `${config.domain}${isProd() ? 'game' : 'build'}/v${content["game-version"].split('.').slice(-1)}/`,
                    },
                    adInterval: +content["preroll-interval"],
                    pushPrompt: {
                        startLevel: +content["push-prompt-start-level"],
                        total: +content["push-prompt-total"],
                    }
                });
            })
            .catch((e) => {
                const buildVersion = localStorage.getItem('buildVersion');

                this.setState({
                    adInterval:  0,
                    unityConfig: {
                        buildVersion: buildVersion || config.buildVersion,
                        buildUrl: `${config.domain}${isProd() ? 'game': 'build'}/v${buildVersion ? buildVersion.split('.').slice(-1) : '1'}/`,
                    }
                })

                localStorage.setItem('rid', config.rid);
                localStorage.setItem('buildVersion', config.buildVersion);

                Sentry.captureException(e);
            })
            .finally(() => {
                checkAdBlockers((adsBlocked) => this.resolveAdsBlocked(adsBlocked));
            });
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    render() {
        const {
            phase,
            offerId,
            analytics,
            adBlocker,
            adInterval,
            pushPrompt,
            unityConfig,
            termsModalOpen,
            termsModalText,
            showAdBlockerModal
        }  = this.state;

        return (
            <>
                { phase === "landing" &&
                    <Landing
                        onPlayBtnClick={this.onPlayBtnClickHandler}
                        showTermsUse={this.showTermsUse}
                    />
                }
                { phase === "game" && !!analytics && !!unityConfig &&
                    <GameContainer
                        rid={this.rid}
                        offerId={offerId}
                        adInterval={adInterval}
                        pushPrompt={pushPrompt}
                        gameAnalytics={analytics}
                        unityConfig={unityConfig}
                        sharpstarService={this.sharpstarDataService}
                    />
                }
                { showAdBlockerModal &&
                    <div className={"blocked-game"}>
                        <AdBlockerModal adBlockOn={adBlocker}/>
                    </div>
                }
                { termsModalOpen &&
                    <Modal
                        title={termsModalText.title}
                        content={termsModalText.content}
                        onClose={this.hideTermsUse}
                    />
                }
            </>
        );
    }
}

export default App;
