import React, {useContext, useEffect, useState} from 'react';
import hasAdIframeElement from "App/Helpers/hasAdIframeElement";
import { environment } from "../Helpers/enviroments";
import config from "../../Pages/games/aq/web/snake-unity/config";

const AdServiceContext = React.createContext();
export const useAdService = () => useContext(AdServiceContext);

/**
 * Ad service provider
 *
 * @param analyticsService: object (required) - initialized analytics instance
 * @param adInterval: number (required)
 * @param children: React.Node || React.Element (required)
 */
const AdService = ({ analyticsService, adInterval, children }) => {
    const [level, setLevel] = useState(null);
    const [prerollCount, setPrerollCount] = useState(0);

    useEffect(() => {
        initAdService();
    }, [])

    useEffect(() => {
        if (prerollCount > 0) {
            analyticsService
                .sendAdEvent('Show', 'RewardedVideo', config.adProvider, 'snakecolorbrake_preroll');
            analyticsService
                .sendDesignEvent("AdShown:snakecolorbrake_preroll:Level", level);
        }
    }, [prerollCount])

    /**
     * triggers useEffect by updating PrerollCount to send analytics event after preroll ends
     */
    const triggerPrerollCompleted = () => {
        setPrerollCount((prev) => prev + 1);
    }

    /**
     * initialize ad provider
     */
    const initAdService = () => {
        environment() !== 'production' && console.info(
            '%cstart Init AD Service',
            'background: #D2FBD0; color: #094205; font-weight: 900'
        );

        // // Initializes a global aiptag.cmd function
        window.aiptag = window.aiptag || { cmd: [] };
        aiptag.cmd.display = aiptag.cmd.display || [];
        aiptag.cmd.player = aiptag.cmd.player || [];

        //todo: decide if show this button at all
        //CMP tool settings
        aiptag.cmp = {
            show: true,
            button: false,
            position: "centered", //centered, bottom
            buttonText: "Privacy settings",
            buttonPosition: "top-right" //bottom-left, bottom-right, top-left, top-right
        }

        //Initialize the Video player
        aiptag.cmd.player.push(function () {
            aiptag.adplayer = new aipPlayer({
                AD_WIDTH: 960,
                AD_HEIGHT: 540,
                AD_DISPLAY: 'fullscreen', //default, fullscreen, center, fill
                LOADING_TEXT: 'loading advertisement',
                PREROLL_ELEM: function () {
                    return document.getElementById('snakecolorbrake-com_preroll');
                },
                AIP_COMPLETE: function () {
                    /*******************
                     ***** WARNING *****
                     *******************
                     Please do not remove the PREROLL_ELEM from the page, it will be hidden automaticly.
                     If you do want to remove it use the AIP_REMOVE callback.
                     */
                    triggerPrerollCompleted();
                },
            });
        });
    }

    /**
     * Show Adds preroll or send error event to game analytics
     *
     * @param level: number(required)
     */
    const showPreroll = (level) => {
        setLevel(level);

        if (typeof aiptag.adplayer !== 'undefined') {
            aiptag.cmd.player.push( function() {
                aiptag.adplayer.startPreRoll();
            });
        } else {
            analyticsService
                .sendAdEvent('FailedShow', 'RewardedVideo', config.adProvider, 'snakecolorbrake_preroll');
            analyticsService
                .sendErrorEvent('Error',"Failed to show preroll");
        }
    }

    /**
     * Set benner visibility
     *
     * @param refElement: <HTMLElement>(required)
     * @param visible: <HTMLElement>(boolean)
     */
    const setBannerVisible = (refElement, visible) => {
        visible ? refElement.classList.add("visible") : refElement.classList.remove("visible");
    }

    /**
     * Update add banner content
     *
     * @param bannerId: string(required)
     * @param bannerElement: <HTMLElement>(required)
     * @param level: number(required)
     */
    const updateAd = (bannerId, bannerElement, level) => {
        console.log('call update banner: ', bannerId);

        aiptag.cmd.display.push( function() {
            aipDisplayTag.display(bannerId);
        });

        // check if ad container has ad iframe after call adUpdate method (aipDisplayTag.display) and send evet to analyticsService
        setTimeout(() => {
            if (hasAdIframeElement(bannerElement)) {
                analyticsService.sendAdEvent('Show', 'Banner', config.adProvider, `snakecolorbrake_${bannerId.slice(20).toLowerCase()}`);
                analyticsService.sendDesignEvent(`AddShown:snakecolorbrake_${bannerId.slice(20).toLowerCase()}:Level`, level);
            } else {
                analyticsService.sendAdEvent('FailedShow', 'Banner', config.adProvider, `snakecolorbrake_${bannerId.slice(20).toLowerCase()}`);
                analyticsService.sendErrorEvent('Warning',`Add placement update failed: ${bannerId}`);
            }
        }, 5000);
    }

    const methods = {
        initAdService,
        showPreroll,
        setBannerVisible,
        updateAd,
        prerollCount,
        adInterval
    }

    return (
        <AdServiceContext.Provider value={methods}>
            { children }
        </AdServiceContext.Provider>
    );
};

export default AdService;