import React, { useEffect, useRef, useState } from "react";
import { isMobile } from 'react-device-detect';
import { Unity, useUnityContext } from "react-unity-webgl";
import {
    useSendAnalytics,
    useSendAnalyticsError,
    useSendProgressionAnalyticsEvent,
    useLevelStarted,
    useLevelEnded,
    useColorChange,
    useResultShow,
    useResultHide,
    useLevelLoaded,
    usePauseShow,
    usePauseHide,
} from "./hooks";
import { useAdService } from "App/Services/AdService";
import { getSecondsDifference, getWindowDimensions, setBodyOverflow } from "../../../../helpers";
import GameLoader from "../GameLoader";
import PushConsentModal from "../../../PushConsentModal";
import "./style.scss";

const GameV2 = ({ gameAnalytics, pushPrompt, unityConfig }) => {
    const [startLoadingTime, setStartLoadingTime] = useState(null);
    const [canvasWidth, setCanvasWidth] = useState('');
    const [canvasHeight, setCanvasHeight] = useState('');
    const [showPushModal, setShowPushModal] = useState(false)

    const refHpBannerBottom = useRef(null);
    const refGameplayBannerBottom = useRef(null);
    const refPauseBannerCenter = useRef(null);
    const refPauseBannerBottom = useRef(null);
    const refRestartBannerCenter = useRef(null);
    const refRestartBannerBottom = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        canvasRef.current && canvasRef.current.addEventListener("mousedown", handleMouseDown, false);

        return () => {
            canvasRef.current.removeEventListener('mousedown', handleMouseDown);
        };
    }, [])

    useEffect(() => {
        setStartLoadingTime(new Date());
    }, [])

    const {
        unityProvider,
        addEventListener,
        removeEventListener,
        sendMessage,
        isLoaded,
        loadingProgression,
    } = useUnityContext({
        loaderUrl: `${unityConfig.buildUrl}WebGL_${unityConfig.buildVersion}.loader.js`,
        frameworkUrl: `${unityConfig.buildUrl}WebGL_${unityConfig.buildVersion}.framework.js.unityweb`,
        dataUrl: `${unityConfig.buildUrl}WebGL_${unityConfig.buildVersion}.data.unityweb`,
        codeUrl: `${unityConfig.buildUrl}WebGL_${unityConfig.buildVersion}.wasm.unityweb`,
        productName: "Snake Color Brake",
        companyName: "Reyo Media Cyprus",
        productVersion: unityConfig.buildVersion,
    });

    useEffect(() => {
        const { width, height } = getWindowDimensions();

        if (isMobile) {
            setBodyOverflow({ overflow: 'hidden' })
            setCanvasWidth('100%');
            setCanvasHeight(`${height}px`);
        } else {
            if (width >= height || width/height > 0.5625) {
                setCanvasHeight('100%');
                setCanvasWidth(`${ height * 9 / 16 }px`);
            } else {
                setCanvasWidth('100%');
                setCanvasHeight(`${ width * 16 / 9 }px`);
            }
        }
    }, []);

    useEffect(() => {
        if (isLoaded) {
            const loadingTime = getSecondsDifference(startLoadingTime, new Date());

            gameAnalytics.sendDesignEvent(`LoadingTime:Time_${loadingTime}`, loadingTime);
        }
    }, [isLoaded])

    useSendAnalytics(gameAnalytics, addEventListener, removeEventListener);
    useSendProgressionAnalyticsEvent(gameAnalytics, addEventListener, removeEventListener);
    useSendAnalyticsError(gameAnalytics, addEventListener, removeEventListener);
    useLevelLoaded(refHpBannerBottom, addEventListener, removeEventListener);
    useLevelStarted(refHpBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener);
    useResultShow(refRestartBannerCenter, refRestartBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener);
    useResultHide(refRestartBannerCenter, refRestartBannerBottom, addEventListener, removeEventListener);
    usePauseShow(refPauseBannerCenter, refPauseBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener);
    usePauseHide(refPauseBannerCenter, refPauseBannerBottom, refGameplayBannerBottom, addEventListener, removeEventListener);

    const startPreroll = useLevelEnded(setShowPushModal, pushPrompt, addEventListener, removeEventListener);
    const gameBgColor = useColorChange(addEventListener, removeEventListener);
    const { prerollCount } = useAdService();

    useEffect(() => {
        if (startPreroll > 0 || showPushModal) {
            console.log("PrerollStart", '=====')
            sendMessage("WebApi", "PrerollStart")
        }
    } ,[startPreroll, showPushModal])

    useEffect(() => {
        if (prerollCount > 0 && !showPushModal)  {
            console.log("PrerollCompleted", '******')
            sendMessage("WebApi", "PrerollCompleted");
            canvasRef.current && canvasRef.current.focus();
        }
    }, [prerollCount, showPushModal])

    const handleMouseDown = () => {
        window.focus();
    }

    return (
        <>
            <div
                className={"game-container"}
                style={{
                    background: gameBgColor || "#FFFFFF",
                    height: isMobile ? canvasHeight : '100vh'
                }}
            >
                { !isLoaded &&
                    <GameLoader loadingProgression={loadingProgression} />
                }
                <Unity
                    ref={canvasRef}
                    unityProvider={unityProvider}
                    style={{ width: canvasWidth, height: canvasHeight }}
                    devicePixelRatio={window.devicePixelRatio}
                />
                {/* ad preroll element */}
                <div id="snakecolorbrake-com_preroll" />
                {/* ad center banners */}
                <div ref={refRestartBannerCenter} className="center-banner">
                    <div id="snakecolorbrake-com_300x250_restart" />
                </div>
                <div ref={refPauseBannerCenter} className="center-banner">
                    <div id="snakecolorbrake-com_300x250_pause" />
                </div>
                {/* ad bottom banners */}
                <div
                    ref={refHpBannerBottom}
                    id="snakecolorbrake-com_320x50_HP"
                    className="footer-banner-hp"
                />
                <div
                    ref={refGameplayBannerBottom}
                    id="snakecolorbrake-com_320x50_gameplay"
                    className="footer-banner-gameplay"
                />
                <div
                    ref={refRestartBannerBottom}
                    id="snakecolorbrake-com_320x50_restart"
                    className="footer-banner-restart"
                />
                <div
                    ref={refPauseBannerBottom}
                    id="snakecolorbrake-com_320x50_pause"
                    className="footer-banner-pause"
                />
            </div>
            { showPushModal &&
                <PushConsentModal
                    analytics={gameAnalytics}
                    setVisibility={setShowPushModal}
                />
            }
        </>
    );
}

export default GameV2;
